recountViewportHeight = ->
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px')

ready = ->
  menuElement = document.querySelector('#mainMenu')
  return if menuElement == null
  recountViewportHeight()
  $( window ).resize ->
    recountViewportHeight()

$(document).on('turbolinks:load', ready)
