ALLOWED_CONTROLLER = 'maps'
ALLOWED_ACTIONS = ['show', 'index', 'list']

class MapSearch
  constructor: (locations, viewType) ->
    @locations = locations
    @viewType = viewType
    @filters = $(".custom-switch.friendly-type input[type=checkbox]")
    @problemFilter = $(".custom-switch.problems input[type=checkbox]")
    @parkWithFilters = $("[aria-labelledby='dropdownParkWithFilter'] .dropdown-item")
    @buildListeners()

  activateAutoComplete: (locationSelectedCallback, placeSelectedCallback, googleService, placeService, sesionToken) ->
    new MapSearchAutoComplete(this, locationSelectedCallback, placeSelectedCallback, googleService, placeService, sesionToken, @viewType)

  synchronizeWith: (searchEngine) ->
    @searchEngine = searchEngine
    @runFilter()

  buildListeners: ->
    self = this
    @filters.on('change', (event) -> self.runFilter())
    @problemFilter.on('change', (event) -> self.runFilter())
    @parkWithFilters.on('click', (event) ->
      MapSearch.updateParkWithFilter(event.target, self.parkWithFilters)
      self.runFilter()
    )

  prepareFilters: ->
    f = []
    parkWithFilter = @parkWithFilterFactory($('#dropdownParkWithFilter')[0].dataset.parkWithFilter)
    searchTextFilter = @searchLocations($('#search').val())

    if @problemFilter.prop('checked')
      f.push((location) -> location.type == 'friendly_type')
      # search by problems
      problems = $(".custom-switch.problems input[type=checkbox][data-filter-type='Problem reports']").prop('checked')
      if problems && (location.problem_reports || location.work_orders)
        f.push((location) -> location.type == 'problems' && !(location.problem_reports.length > 0 || location.work_orders.length > 0))
    else
      f.push((location) -> location.type == 'problems')

    $.each @filters, (index, filter) ->
      unless filter.checked
        f.push((location) -> location.location_friendly_type == filter.dataset.filterType)
    f.push((location) -> !parkWithFilter(location))

    f

  searchLocations: (searchedText) ->
    (location) ->
      return true if searchedText == ''
      MapSearch.searching(location, searchedText)

  @searching: (location, searchedText) ->
    street_address = if location.street_address == null then '' else location.street_address
    city = if location.street_address == null then '' else location.city
    location.human_name.toLowerCase().indexOf(searchedText.toLowerCase()) != -1 ||
      street_address.toLowerCase().indexOf(searchedText.toLowerCase()) != -1 ||
      city.toLowerCase().indexOf(searchedText.toLowerCase()) != -1

  @updateParkWithFilter: (activeFilter, parkWithFilters) ->
    $('#dropdownParkWithFilter').html(activeFilter.innerHTML)
    $('#dropdownParkWithFilter')[0].dataset.parkWithFilter = activeFilter.dataset.parkWithFilter
    $.each parkWithFilters, (index, filter) ->
      return filter.style.cssText = 'display:none !important' if activeFilter.dataset.parkWithFilter == filter.dataset.parkWithFilter
      filter.style.cssText = 'display:block !important'

  parkWithFilterFactory: (activeFilter) ->
    if activeFilter == 'clipper-card'
      (location) -> location.can_use_clipper_card
    else if activeFilter == 'bikelink-card'
      (location) -> location.can_use_bikelink_card

  runFilter: ->
    @searchEngine.call(@prepareFilters())

  runChecking: (filters, location) ->
    show = true
    $.each filters, (index, filter) ->
      return show = false if filter(location) && show
    show

  @refreshFilters: (oldFilters) ->
    $.each $(".custom-switch input[type=checkbox]"), (index, filter) ->
      $.each oldFilters, (index, oldFilter) ->
        filter.checked = oldFilter.checked if filter.dataset.filterType == oldFilter.dataset.filterType

  # update search fields with values from previous view
  @refreshSearchState: (oldSearchFilter, oldFilter, oldParkWithFilter)->
    MapSearch.refreshFilters(oldFilter)
    MapSearch.updateParkWithFilter(oldParkWithFilter, $("[aria-labelledby='dropdownParkWithFilter'] .dropdown-item"))
    $('#search').val(oldSearchFilter.val())

class MapSearchAutoComplete

  AUTOCOMPLETE_MAX_LIMIT: 10
  AUTOCOMPLETE_MAP_LIMIT: 7

  constructor: (mapSearch, locationSelectedCallback, placeSelectedCallback, googleService, placeService, sesionToken, viewType) ->
    @mapSearch = mapSearch
    @locations = mapSearch.locations
    @locationSelectedCallback = locationSelectedCallback
    @placeSelectedCallback = placeSelectedCallback
    @googleService = googleService
    @placeService = placeService
    @sesionToken = sesionToken
    @viewType = viewType
    @buildListeners()

  buildListeners: ->
    self = this
    @activateSearchingListener()
    $(document).on('click', (event) -> self.removeAutoCompleteFields() if !$(event.target).parents().is('.autocomplete'))
    $('#search').on('focus', (event) -> self.buildAutoComplete(event.target.value))
    $('#search').on('keydown', (event) ->
      if event.keyCode == 13
        return self.mapSearch.runFilter() if self.viewType == 'list'
        self.activeFirstSuitableLocation()
    )

  activateSearchingListener: ->
    self = this
    waitInputCallback = null
    $('#search').on('input', (event) ->
      callback = ->
        waitInputCallback = null
        self.buildAutoComplete(event.target.value)
      waitInputCallback = setTimeout(callback, 200) unless waitInputCallback
    )

  activeFirstSuitableLocation: ->
    location = @containsLocation $('#search').val()
    locationElement = if location == null then $('.autocomplete-items div')[0] else location.parentNode
    return unless locationElement
    @autoCompleteClicked locationElement
    @removeAutoCompleteFields()

  containsLocation: (searchedText) ->
    result = null
    $.each $('.location-name'), (index, location) ->
      return result = location if location.innerHTML.toLowerCase() == searchedText.toLowerCase()
    result

  autoCompleteClicked: (clickedField) ->
    self = this
    self.removeAutoCompleteFields()
    $('#search').val(clickedField.querySelector('.location-name').innerHTML)
    return @locationSelectedCallback(Number(clickedField.dataset.locationId), clickedField.dataset.latitude, clickedField.dataset.longitude) if clickedField.dataset.exact == 'true'
    self.placeService.getDetails({ placeId: clickedField.dataset.placeId, fields: ['geometry']}, (place, status) ->
      return unless status == google.maps.places.PlacesServiceStatus.OK
      self.placeSelectedCallback(place.geometry.location)
    )

  buildAutoComplete: (searchedText) ->
    @removeAutoCompleteFields()
    return [] if searchedText == ''
    suitableLocations = @findSuitableLocations(searchedText)
    @initializeFields(searchedText, suitableLocations)

  findSuitableLocations: (searchedText) ->
    self = this
    preparedFilters = @mapSearch.prepareFilters()
    foundLocations = []
    $.each @locations, (index, location) ->
      foundLocations.push location if MapSearch.searching(location, searchedText) && self.mapSearch.runChecking(preparedFilters, location)
    foundLocations[0..@bikeLinkLocationLimit(foundLocations.length) - 1]

  bikeLinkLocationLimit: (numberOfLocations) ->
    return @AUTOCOMPLETE_MAP_LIMIT if numberOfLocations >= @AUTOCOMPLETE_MAP_LIMIT
    numberOfLocations

  initializeFields: (searchedText, suitableLocations) ->
    self = this
    googleParams = { input: searchedText, sessionToken: @sesionToken, componentRestrictions: { country: 'us' }}
    @googleService.getPlacePredictions( googleParams, (predictions, status) ->
      self.initializeFieldsWithGooglePredictions(suitableLocations, predictions, status)
    )

  initializeFieldsWithGooglePredictions: (suitableLocations, predictions, status) ->
    self = this
    @addBikeLinkLocations(suitableLocations)
    @addGoogleLocations(suitableLocations, predictions, status) unless suitableLocations.length == @AUTOCOMPLETE_MAX_LIMIT
    $('.autocomplete-items')[0].style.display = 'inline-table'
    $('.autocomplete-items div').on('click', (event) -> self.autoCompleteClicked(event.delegateTarget))

  addBikeLinkLocations: (suitableLocations) ->
    self = this
    $.each suitableLocations, (index, location) ->
      self.buildAutoCompleteField(self.iconOf(location), location.human_name,
        { locationId: location.id, latitude: location.latitude, longitude: location.longitude, exact: true })

  addGoogleLocations: (suitableLocations, predictions, status) ->
    return unless status == google.maps.places.PlacesServiceStatus.OK
    self = this
    limit = @AUTOCOMPLETE_MAX_LIMIT - suitableLocations.length
    $.each predictions, (index, prediction) ->
      return if limit == 0
      self.buildAutoCompleteField('', prediction.terms[0..-2].map((t) -> t.value).join(', '), { placeId: prediction.place_id, exact: false })
      limit -= 1
    @addGoogleLogo()

  addGoogleLogo: ->
    logoField = document.createElement('span')
    logoField.className = 'd-flex flex-row google-logo'
    $('.autocomplete-items')[0].appendChild(logoField)

  buildAutoCompleteField: (iconStyle, name, dataset) ->
    textField = document.createElement('div')
    textField.className = 'd-flex flex-row'
    $.each dataset, (key, value) -> textField.dataset[key] = value
    textField.innerHTML = "<span class='icon #{iconStyle}'></span>
                           <span class='location-name pl-3 flex-grow-1 align-self-center'>#{name}</span>"
    $('.autocomplete-items')[0].appendChild(textField)

  removeAutoCompleteFields: ->
    @removeItems $('.autocomplete-items div')
    @removeItems $('.autocomplete-items .google-logo')
    autoCompleteRoot = $('.autocomplete-items')[0]
    autoCompleteRoot.style.display = 'none' if autoCompleteRoot

  removeItems: (items) ->
    $.each items, (index, item) ->
      item.parentNode.removeChild item

  iconOf: (location) ->
    style = { 'eLocker': 'locker', 'Group Parking': 'group', 'Vendor': 'vendor' }
    style[location.location_friendly_type]

window.MapSearch = MapSearch

refreshState = ->
  return unless continueIfAllowed(ALLOWED_CONTROLLER, ALLOWED_ACTIONS)

  searchFilter = $('#search')
  filters = $(".custom-switch input[type=checkbox]")
  parkWithFilter = $('#dropdownParkWithFilter')[0]
  $(document).on('turbolinks:render', (event) ->
    return unless continueIfAllowed(ALLOWED_CONTROLLER, ALLOWED_ACTIONS)

    window.MapSearch.refreshSearchState(searchFilter, filters, parkWithFilter)
    $(document).off('turbolinks:render') unless document.documentElement.hasAttribute("data-turbolinks-preview")
  )

$(document).on('turbolinks:before-visit', refreshState)
