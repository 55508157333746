ALLOWED_CONTROLLER = 'maps'
ALLOWED_ACTIONS = ['show', 'index']
MAP_URL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key='

class TextSearchingEngine
  constructor: (mapSearch, showCallback, hideCallback) ->
    @mapSearch = mapSearch
    @locations = mapSearch.locations
    @showCallback = showCallback
    @hideCallback = hideCallback
    @mapSearch.synchronizeWith this

  call: (filters) ->
    self = this
    $.each @locations, (index, location) ->
      return self.showCallback(location.id) if self.mapSearch.runChecking(filters, location)
      self.hideCallback(location.id)

class Map

  constructor: (map) ->
    @setMapDimensions(map)
    @locations = JSON.parse(map.getAttribute('data-locations'))
    @map = new google.maps.Map(map, { zoom: @initialZoom(), center: @initialLocation(), mapTypeControl: true, gestureHandling: 'cooperative' })
    @googleService = new google.maps.places.AutocompleteService()
    @placeService = new google.maps.places.PlacesService(@map)
    @sesionToken = new google.maps.places.AutocompleteSessionToken()
    @initPostMapActions()
    @showLocalVendorTooltip(map.getAttribute('data-local-vendor-orientation'), $(".vendor-filter"), $("#vendor_filter"))

  setMapDimensions: (map) ->
    return if DeviceResolver.desktopWidth() && !(DeviceResolver.isMobile())
    padding = document.querySelector('header').offsetHeight + document.querySelector('.container-fluid > .navbar').offsetHeight
    map.style.paddingBottom = "calc(var(--vh, 1vh) * 100 - " + padding + "px)"

  showLocalVendorTooltip: (tooltipOrientation, vendorFilter, vendorCheckbox) ->
    return unless tooltipOrientation

    $('#listFilter').addClass('show')
    vendorFilter.popover({
      template: "<div class='popover local-vendor' role='tooltip'><div class='arrow'></div><div class='popover-body'></div></div>"
      content: I18n.t('modern.maps.side_form.filtering.local_vendors.tooltip_html')
      placement: tooltipOrientation
      html: true
      trigger: 'focus' })
    vendorCheckbox.focus()
    vendorCheckbox.on('blur', (event) ->
      vendorFilter.popover('dispose')
      vendorCheckbox.off('blur')
    )

  initPostMapActions: ->
    self = this
    self.markers = new window.CustomMapMarkersCollection(self.locations, self.map)
    google.maps.event.addListenerOnce(@map, 'idle', () -> self.mapLoadedCallback())
    @map.addListener('bounds_changed', () -> self.mapBoundaryChanged())

  mapLoadedCallback: ->
    return if @mapSearch
    if $('#search').val() == '' then @updateLocationBasedOnPosition() else @centerToSearchedLocation()
    @mapSearch = new window.MapSearch(@locations, 'map')
    @textSearchEngine = new TextSearchingEngine(@mapSearch, @markers.show, @markers.hide)
    @mapSearch.activateAutoComplete(@moveToExactLocation, @moveToPlaceLocation, @googleService, @placeService, @sesionToken)

  mapBoundaryChanged: ->
    @markers.showLocationsInBox(@map.getBounds().getNorthEast().lat(), @map.getBounds().getNorthEast().lng(),
                                @map.getBounds().getSouthWest().lat(), @map.getBounds().getSouthWest().lng())
    @mapSearch.runFilter() if @mapSearch

  centerToSearchedLocation: ->
    searchedText = $('#search').val()
    exactLocation = @containsBikeLinkLocation searchedText
    return @moveToExactLocation(exactLocation.id, exactLocation.latitude, exactLocation.longitude) unless exactLocation == null
    @centerGoogleSearchedLocation(searchedText)

  containsBikeLinkLocation: (searchedText) ->
    result = null
    $.each @locations, (index, location) ->
      return result = location if location.human_name.toLowerCase() == searchedText.toLowerCase()
    result

  centerGoogleSearchedLocation: (searchedText) ->
    self = this
    googleParams = { input: searchedText, sessionToken: @sesionToken, componentRestrictions: { country: 'us' }}
    @googleService.getPlacePredictions( googleParams, (predictions, status) ->
      return unless status == google.maps.places.PlacesServiceStatus.OK
      self.placeService.getDetails({ placeId: predictions[0].place_id, fields: ['geometry']}, (place, status) ->
        return unless status == google.maps.places.PlacesServiceStatus.OK
        self.moveToPlaceLocation(place.geometry.location)
      )
    )

  moveToExactLocation: (id, latitude, longitude) =>
    @map.setCenter(new google.maps.LatLng(latitude, longitude))
    @map.setZoom(17)
    @markers.activate(id)

  moveToPlaceLocation: (position) =>
    @map.setCenter(position)
    @map.setZoom(17)

  initialZoom: ->
    return 14 if @locations.length == 1
    4

  initialLocation: ->
    return { lat: @locations[0].latitude, lng: @locations[0].longitude } if @locations.length == 1
    { lat: 40.922846, lng: -102.138878 }

  updateLocationBasedOnPosition: ->
    self = this
    if navigator.geolocation && @locations.length > 1
      navigator.geolocation.getCurrentPosition( (position) ->
        self.map.setCenter new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
        self.map.setZoom 8
      )

ready = ->
  return unless continueIfAllowed(ALLOWED_CONTROLLER, ALLOWED_ACTIONS)
  mapElement = document.querySelector('[data-selector="map"]')
  return if mapElement == null

  if document.googleMapLib
    new Map(mapElement)
  else
    $.ajax(MAP_URL + mapElement.getAttribute('google-key') + '&libraries=places', {
      crossDomain: true,
      dataType: 'script',
      success: (data) ->
        document.googleMapLib = true
        new Map mapElement
    })

$(document).on('turbolinks:load', ready)
