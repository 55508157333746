ALLOWED_CONTROLLER = 'payments'
ALLOWED_ACTIONS = ['new', 'create']

class PaymentsController
  constructor: ->
    @initForm()
    @attachListeners()

  initForm: ->
    @reloadBillingAddressForm $('[id*="_same_billing_address"]')[0].checked

  attachListeners: ->
    $('[id*="_same_billing_address"]').on('change', @billingAddressChanged.bind(this))

  billingAddressChanged: (event) ->
    @reloadBillingAddressForm event.target.checked

  reloadBillingAddressForm: (checked) ->
    return $('#billing-address').show() unless checked
    $("#billing-address :text").val('')
    $('#billing-address').hide()


ready = ->
  return unless continueIfAllowed(ALLOWED_CONTROLLER, ALLOWED_ACTIONS)
  new PaymentsController()

$(document).on('turbolinks:load', ready)
