/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import I18n from '../components/i18n-js/index.js.erb'

import { tooltipTranslation } from '../components/modern/tooltip_texts'
import '../components/modern/payments'
import '../components/modern/behavior/zip_san_diego'
import '../components/modern/maps/custom_marker'
import '../components/modern/maps/custom_bubble'
import '../components/modern/maps/device_resolver'
import '../components/modern/maps/distance_services'
import '../components/modern/maps/list'
import '../components/modern/maps/map'
import '../components/modern/maps/search'
import '../components/modern/braintree_form.coffee.erb'
import '../components/modern/init'
import '../components/modern/menu'
import '../components/modern/payments'

import 'select2'
import 'select2/dist/css/select2.css'

import 'bootstrap/dist/js/bootstrap'

require('jstree');
require("stylesheets/modern.scss")

// https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
// jquery 3.x call load function different. This is fallback for legacy code to working
jQuery.fn.load = function(callback){ $(window).on("load", callback) };

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.jQuery = jQuery;
window.$ = $;
window.I18n = I18n

window.addEventListener('DOMContentLoaded', () => {
    $('.js-states').select2()
})
console.log(I18n.t('views.cards.types.bikelink_app'))

$(document).on('turbolinks:load', function () {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').not('[data-translate]').tooltip();

    window.setTimeout(function () {
        $(".alert-success.alert-dismissible").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
        });
    }, 5000);
    $('[data-translate]').tooltip({title: tooltipTranslation, html: true});
    $("div.location-details").on("click", ".close-modal", function () {
        $("div.location-details").removeClass('show');
    });

    $('#verification-send-code, .send-by-text, .send-by-call').click(function () {
        $('#verification-send-code').fadeOut();
        $('#verification-code-input').fadeIn();
    });

    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
        }
        var $subMenu = $(this).next('.dropdown-menu');
        $subMenu.toggleClass('show');


        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
            $('.dropdown-submenu .show').removeClass('show');
        });

        return false;
    });
});

$(document).on('turbolinks:click', function () {
    $('.modal').hide();
    $('.modal-backdrop').hide();
});

