ALLOWED_CONTROLLER = 'maps'
ALLOWED_ACTIONS = ['list']
MAP_URL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key='

class TextSearchingEngine

  MAX_LOCATION_DISTANCE: 10 # miles

  constructor: (mapSearch, showCallback, hideCallback, googleService, placeService, sesionToken) ->
    @mapSearch = mapSearch
    @locations = mapSearch.locations
    @showCallback = showCallback
    @hideCallback = hideCallback
    @googleService = googleService
    @placeService = placeService
    @sesionToken = sesionToken
    @mapSearch.synchronizeWith this

  call: (filters) ->
    searchedText = $('#search').val()
    foundLocations = @suitableBikeLinkLocations searchedText
    return @simpleListSearching(filters, searchedText) if foundLocations
    @googleSearchingLocations(filters, searchedText)

  simpleListSearching: (filters, searchedText) ->
    self = this
    $.each @locations, (index, location) ->
      if self.mapSearch.runChecking(filters, location) && window.MapSearch.searching location, searchedText
        return self.showCallback(location.id)
      self.hideCallback(location.id)

  suitableBikeLinkLocations: (searchedText) ->
    foundLocations = false
    $.each @locations, (index, location) ->
      return foundLocations = true if window.MapSearch.searching location, searchedText
    foundLocations

  googleSearchingLocations: (filters, searchedText) ->
    self = this
    googleParams = { input: searchedText, sessionToken: @sesionToken, componentRestrictions: { country: 'us' }}
    @googleService.getPlacePredictions( googleParams, (predictions, status) ->
      return unless status == google.maps.places.PlacesServiceStatus.OK
      self.placeService.getDetails({ placeId: predictions[0].place_id, fields: ['geometry']}, (place, status) ->
        return unless status == google.maps.places.PlacesServiceStatus.OK
        self.filterLocationsBasedOnDistance(filters, place.geometry.location)
      )
    )

  filterLocationsBasedOnDistance: (filters, position) ->
    self = this
    bestResult = null
    foundAtLeastOneResult = false
    $.each @locations, (index, location) ->
      distance = window.distanceServices.distanceInBetweenGPSCoordinates({ latitude: location.latitude, longitude: location.longitude },
        { latitude: position.lat(), longitude: position.lng() })
      notFiltered = self.mapSearch.runChecking(filters, location)
      if distance <= self.MAX_LOCATION_DISTANCE && notFiltered
        self.showCallback(location.id)
        foundAtLeastOneResult = true
      else
        self.hideCallback(location.id)
        bestResult = { location_id: location.id, distance: distance } if (bestResult == null || bestResult.distance > distance) && notFiltered
    self.showCallback(bestResult.location_id) if bestResult != null && !foundAtLeastOneResult

class MapList

  constructor: (list) ->
    @locations = JSON.parse(list.getAttribute('data-locations'))
    @locationElements = @loadLocations()
    @googleService = new google.maps.places.AutocompleteService()
    @placeService = new google.maps.places.PlacesService(document.createElement('div'))
    @sesionToken = new google.maps.places.AutocompleteSessionToken()
    @mapSearch = new window.MapSearch(@locations, 'list')
    @textSearchEngine = new TextSearchingEngine(@mapSearch, @show, @hide, @googleService, @placeService, @sesionToken)
    @mapSearch.activateAutoComplete(@moveToExactLocation, @moveToPlaceLocation, @googleService, @placeService, @sesionToken)
    @updateLocationTimer = null

  loadLocations: ->
    res = {}
    $.each $('.list-wrapper li'), (index, locationElement) ->
      res[locationElement.dataset.locationId] = locationElement
    res

  updateLocationCount: ->
    $('.list .list-header').html(I18n.t('modern.maps.list.total_count', number: $('.list-wrapper li:visible').length))
    @updateLocationTimer = null

  moveToExactLocation: (id, latitude, longitude) =>
    searchedText = $('#search').val()
    @textSearchEngine.simpleListSearching(@mapSearch.prepareFilters(), searchedText)

  moveToPlaceLocation: (position) =>
    @textSearchEngine.filterLocationsBasedOnDistance(@mapSearch.prepareFilters(), position)

  hide: (location_id) =>
    return unless @locationElements[location_id]
    @locationElements[location_id].style.cssText = 'display:none !important'
    @updateLocationTimer = setTimeout(@updateLocationCount.bind(this), 10) unless @updateLocationTimer

  show: (location_id) =>
    return unless @locationElements[location_id]
    @locationElements[location_id].style.cssText = 'display:flex !important'
    @updateLocationTimer = setTimeout(@updateLocationCount.bind(this), 10) unless @updateLocationTimer

ready = ->
  return unless continueIfAllowed(ALLOWED_CONTROLLER, ALLOWED_ACTIONS)
  listElement = document.querySelector('[data-selector="list"]')
  return if listElement == null

  if document.googleMapLib
    new MapList(listElement)
  else
    $.ajax(MAP_URL + listElement.getAttribute('google-key') + '&libraries=places', {
      crossDomain: true,
      dataType: 'script',
      success: (data) ->
        document.googleMapLib = true
        new MapList(listElement)
    })

$(document).on('turbolinks:load', ready)
