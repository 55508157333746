class ZipSanDiego
  constructor: (@zip) ->
    @min = parseInt(@zip.data('min'))
    @max = parseInt(@zip.data('max'))
    @zipSanDiego = $('#zipSanDiego')
    unless isNaN(@min) || isNaN(@max)
      @zip.change =>
        @openZipSanDiego()


  openZipSanDiego: ->
    zip = parseInt(@zip.val())
    return if isNaN(zip)
    if zip >= @min && zip <= @max
      @zipSanDiego.modal()

ready = ->
 $("*[data-behavior=ZipSanDiego]").each ->
    new ZipSanDiego($(@))

$(document).on('turbolinks:load', ready)
